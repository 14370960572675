import moment from "moment";
import { Link } from "react-router-dom";
import { Space, Button, Table, Popconfirm, Tag, Radio } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";

import { useBonds } from "./useBonds";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { Bond } from "@/graphql";

export const BondWidget = () => {
  const { loading, data, setPage, deleteBond, payout, isPayoutLoading } =
    useBonds();
  const [currentBond, setCurrentBond] = useState<Bond>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<string>();

  return (
    <>
      <div className="page-header">
        <h3>Бонд жагсаалт</h3>
        <Space>
          <Link to={"/bonds/add"}>
            <Button type="primary" icon={<PlusOutlined />}>
              Бонд нэмэх
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.bonds ?? []}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 20,
              total: Number(data?.totalPages) * 20,
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            bordered
            columns={[
              {
                title: "Нэр",
                dataIndex: "name",
                key: "name",
                render: (text) => <span>{text}</span>,
              },
              {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                render: (text) => <Tag>{text}</Tag>,
              },
              {
                title: "Хүү",
                dataIndex: "interestRate",
                key: "interestRate",
                render: (text) => <span>{text}%</span>,
              },
              {
                title: "Хугацаа",
                dataIndex: "duration",
                key: "duration",
                render: (text) =>
                  text && (
                    <span>
                      {text.endsWith("y")
                        ? `${text.substring(0, text.length - 1)} жил`
                        : `${text.substring(0, text.length - 1)} сар`}
                    </span>
                  ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "140px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Link to={"/bonds/edit/" + record._id}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EditOutlined />}
                        />
                      </Link>
                      {record.paymentSchedules &&
                        record.paymentSchedules.length > 0 && (
                          <Button
                            type="primary"
                            shape="circle"
                            disabled={loading}
                            icon={<PaperClipOutlined />}
                            onClick={() => {
                              setCurrentBond(record);
                              setIsModalOpen(true);
                            }}
                          />
                        )}
                      <Popconfirm
                        disabled={loading}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          if (record._id) {
                            deleteBond(record._id);
                          }
                        }}
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
      <Modal
        open={isModalOpen}
        title="Хүү олгогдох хуваарь"
        okText="Хүү олгох"
        onCancel={() => {
          setIsModalOpen(false);
          setCurrentBond(undefined);
          setSelectedSchedule(undefined);
        }}
        onOk={() => {
          if (selectedSchedule) {
            const batchId = selectedSchedule.replaceAll("-", "");
            if (currentBond) {
              payout({ batchId, bondId: currentBond._id || "" });
            }
            setIsModalOpen(false);
          }
        }}
      >
        <div>
          <Radio.Group
            value={selectedSchedule}
            onChange={(e) => setSelectedSchedule(e.target.value)}
          >
            {currentBond?.paymentSchedules?.map((item, index) => (
              <Radio key={index} value={item}>
                {item}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
};
