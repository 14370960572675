import moment from "moment";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";
import {
  ExportInterestPayoutReportForDateRangeMutationVariables,
  ReportSummary,
} from "@/graphql";
import { DateRangeModal } from "../../widgets/DateRangeModal";
import { DateModal } from "@/widgets/DateModal";

export function InterestPayoutReportsPage() {
  const { data, isLoading } = useQuery({
    queryKey: ["reports", "interest-payout"],
    queryFn: () => sdk.interestPayoutReports(),
    select: (data) => data.interestPayoutReports,
  });
  const { mutate, isLoading: isDownloadingReport } = useMutation({
    mutationFn: async (batchId: string) => {
      const {
        downloadInterestPayoutReport: { reportUrl },
      } = await sdk.downloadInterestPayoutReport({ batchId });
      window.open(reportUrl);
    },
  });

  const [showCreateReportModal, setShowCreateReportModal] = useState(false);
  const [showCreatePeportModalBatchId, setShowCreatePeportModalBatchId] =
    useState(false);

  const { mutate: createReport, isLoading: isCreatingReport } = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
    }: ExportInterestPayoutReportForDateRangeMutationVariables) => {
      const {
        exportInterestPayoutReportForDateRange: { reportUrl },
      } = await sdk.exportInterestPayoutReportForDateRange({
        startDate,
        endDate,
      });
      window.open(reportUrl);
    },
    onSettled: () => setShowCreateReportModal(false),
  });

  const { mutate: createBatchReport, isLoading: isCreatingBatchReport } =
    useMutation({
      mutationFn: async (date: string) => {
        const {
          exportInterestPayoutReport: { reportUrl },
        } = await sdk.exportInterestPayoutReport({
          batchId: date,
        });
        window.open(reportUrl);
      },
      onSettled: () => setShowCreatePeportModalBatchId(false),
    });

  return (
    <main className="page">
      <DateRangeModal
        title="Тайлан үүсгэх"
        show={showCreateReportModal}
        onClose={setShowCreateReportModal.bind(null, false)}
        loading={isCreatingReport}
        onSubmit={(startDate, endDate) =>
          createReport({
            startDate: startDate.format("YYYYMMDD"),
            endDate: endDate.format("YYYYMMDD"),
          })
        }
      />
      <DateModal
        title={"Тайлан дахин үүсгэх"}
        loading={isCreatingBatchReport}
        show={showCreatePeportModalBatchId}
        onClose={setShowCreatePeportModalBatchId.bind(null, false)}
        onSubmit={(date) => {
          createBatchReport(date.format("YYYYMMDD"));
        }}
      />
      <div className="page-header">
        <h3>Хүү олголтын тайлан</h3>
        <Space size={12}>
          <Button
            type="primary"
            onClick={() => setShowCreatePeportModalBatchId(true)}
          >
            Тайлан дахин үүсгэх
          </Button>
          <Button type="primary" onClick={() => setShowCreateReportModal(true)}>
            Тайлан үүсгэх
          </Button>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table loading={isLoading} dataSource={data}>
            <Table.Column
              title="Огноо"
              dataIndex="batchId"
              render={(value: string) =>
                moment(value, "YYYYMMDD").format("YYYY.MM.DD")
              }
            />
            <Table.Column
              title="Үйлдэл"
              render={(_, { batchId }: ReportSummary) => (
                <Space>
                  <Button
                    type="primary"
                    shape="circle"
                    disabled={isDownloadingReport}
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      if (batchId) {
                        mutate(batchId);
                      }
                    }}
                  />
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </main>
  );
}
