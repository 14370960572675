import moment from "moment";
import { useHistory } from "react-router-dom";
import { Space, Button, Table, Tag, message } from "antd";
import { FileProtectOutlined, FileAddOutlined } from "@ant-design/icons";

import { GetOrderListsQuery, OrderListStatus } from "@/graphql";

import { useOrderLists } from "./useOrderLists";
import { useOrderListDetail } from "./useOrderListDetail";
import { useDownloadOrderList } from "./useDownloadOrderList";

export type OrderListItem =
  GetOrderListsQuery["orderLists"]["orderLists"][number];

function DownloadOrderListButton({ orderListId }: { orderListId: string }) {
  const { loading, startDownload } = useDownloadOrderList(orderListId);
  const {
    loading: generating,
    generateOrderListDetail,
    generateOrderListOrders,
    data: url,
    orderData: urlOrder,
  } = useOrderListDetail(orderListId);
  return (
    <div style={{ display: "flex" }}>
      <Button loading={loading} onClick={startDownload}>
        Татаж авах
      </Button>
      <div style={{ marginLeft: 20 }}>
        {url ? (
          <Button>
            <a href={url} download>
              Дэлгэрэнгүй файл татах
            </a>
          </Button>
        ) : (
          <Button
            disabled={loading || generating}
            loading={loading || generating}
            onClick={() => generateOrderListDetail()}
          >
            {generating ? "Түр хүлээнэ үү..." : "Файлын хүсэлт илгээх"}
          </Button>
        )}
      </div>
      <div style={{ marginLeft: 20 }}>
        {urlOrder ? (
          <Button>
            <a href={urlOrder} download>
              Цэнэглэлтийн файл татах
            </a>
          </Button>
        ) : (
          <Button
            disabled={loading || generating}
            loading={loading || generating}
            onClick={() => generateOrderListOrders()}
          >
            {generating ? "Түр хүлээнэ үү..." : "Цэнэглэлт"}
          </Button>
        )}
      </div>
    </div>
  );
}

export const OrderListWidget = ({ status }: { status: OrderListStatus }) => {
  const history = useHistory();
  const { loading, data, setPage } = useOrderLists({ status });

  return (
    <>
      <div className="page-header">
        <h3>Захиалгын хуудсууд</h3>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              loading={loading}
              disabled={loading}
              style={{ marginTop: 10 }}
              type="primary"
              icon={<FileAddOutlined />}
              onClick={() => history.push("orderlists/create")}
            >
              Захиалгын хуудас үүсгэх
            </Button>
          </Space>
        </div>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.orderLists ?? []}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 30,
              total: Number(data?.totalPages) * 30,
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            bordered
            columns={[
              {
                title: "№",
                dataIndex: "number",
                key: "number",
                render: (_, __, index) => <span>{index + 1}</span>,
              },
              {
                title: "Төлөв",
                dataIndex: "status",
                key: "status",
                render: (text) => <Tag>{text}</Tag>,
              },
              {
                title: "Нийт захиалгын тоо",
                dataIndex: "totalPurchases",
                key: "totalPurchases",
                render: (_, record) => (
                  <span>{record?.totalPurchases ?? 0}</span>
                ),
              },
              {
                title: "Үүсгэсэн огноо",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (text) => (
                  <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
                ),
              },
              {
                title: "Шинэчлэгдсэн огноо",
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (text) => (
                  <span>
                    {text ? moment(text).format("YYYY/MM/DD HH:mm:ss") : "-"}
                  </span>
                ),
              },
              {
                title: "",
                dataIndex: "download",
                key: "download",
                render: (_, record) =>
                  record._id && (
                    <DownloadOrderListButton orderListId={record._id} />
                  ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "45px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() => {
                          if (record.status === OrderListStatus.Done) {
                            message.warn("Биелэгдсэн захиалгын хуудас байна");
                          } else {
                            history.push(`/orderlist/${record._id}`);
                          }
                        }}
                        icon={<FileProtectOutlined />}
                      />
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
