import moment, { Moment } from "moment";
import { Modal, DatePicker } from "antd";

import { useState } from "react";

type RangeValue = Moment | null;

interface DateModalProps {
  title: string;
  show: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (dateDate: Moment) => void;
}

export function DateModal({
  title,
  show,
  loading,
  onClose,
  onSubmit,
}: DateModalProps) {
  const [date, setDate] = useState<Moment | null>(null);

  return (
    <Modal
      title={title}
      open={show}
      confirmLoading={loading}
      onOk={async () => {
        if (date) {
          onSubmit(date);
        }
      }}
      onCancel={() => {
        onClose();
        setDate(null);
      }}
    >
      <DatePicker
        disabled={loading}
        value={date}
        onChange={(date) => setDate(date)}
        format="YYYY-MM-DD"
      />
    </Modal>
  );
}
