import { Link } from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Switch,
  Col,
  Row,
  Select,
  DatePicker,
  Tag,
} from "antd";

import { useEditBond } from "../useEditBond";

import BlueDivider from "@/components/BlueDivider";
import { useEffect, useState } from "react";
import { format, isValid, parse } from "date-fns";
import moment, { Moment } from "moment";

export const { TextArea } = Input;
const { Option } = Select;

export function EditBondWidget({ _id }: { _id: string }) {
  const { form, loading, editBond, bondData } = useEditBond({
    selectedBondId: _id,
  });

  const [dates, setDates] = useState<Array<string>>([]); // Array of date strings
  const [inputValue, setInputValue] = useState(""); // Current typing value

  // Handle manual input change
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  // Confirm date on Enter key
  const handleInputConfirm = (e: any) => {
    if (e.key === "Enter" && inputValue) {
      const parsedDate = parse(inputValue, "YYYY-MM-DD", new Date());
      const formattedDate = isValid(parsedDate)
        ? format(parsedDate, "YYYY-MM-DD")
        : inputValue;
      if (formattedDate && !dates.includes(formattedDate)) {
        setDates([...dates, formattedDate]);
      }
      setInputValue(""); // Clear input
    }
  };

  // Handle DatePicker selection
  const handleDateChange = (date: Moment | null, dateString: string) => {
    if (dateString && !dates.includes(dateString)) {
      setDates([...dates, dateString]);
      form.setFieldValue("paymentSchedules", [...dates, dateString]);
    }
    setInputValue("");
  };
  useEffect(() => {
    if (bondData) {
      console.log("dates", bondData.paymentSchedules);
      setDates(bondData.paymentSchedules || []);
    }
  }, [bondData]);

  // Remove a date tag
  const handleClose = (removedDate: string) => {
    const dts = dates.filter((date) => date !== removedDate);
    setDates(dts);
    form.setFieldValue("paymentSchedules", dts);
  };

  return (
    <>
      <div className="page-header">
        <h3>Бонд засах</h3>
        <div className="page-header-buttons">
          <Link to="/bonds">
            <Button icon={<ArrowLeftOutlined />} className="back-button">
              Буцах
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-body">
        <Form
          form={form}
          onFinish={editBond}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={11}>
              <BlueDivider
                text="Мэдээлэл"
                styles={{ marginBottom: "30px", marginTop: "10px" }}
              />
              <Form.Item
                label="Нэр (Монгол)"
                name="name_mn"
                rules={[{ required: true, message: "Нэрээ оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Нэр (Англи)"
                name="name_en"
                rules={[{ required: true, message: "Нэр оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[{ required: true, message: "Symbol оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Хүү (%)"
                name="interestRate"
                rules={[{ required: true, message: "Хүү (%) Оруулна уу" }]}
              >
                <Input type="number" disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Бондын төрөл"
                name="paymentType"
                rules={[{ required: true, message: "Бондын төрөл сонгоно уу" }]}
              >
                <Select disabled={loading}>
                  <Option value="fixed">Fixed</Option>
                  <Option value="floating">Floating</Option>
                </Select>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.paymentType !== currentValues.paymentType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("paymentType") === "floating" ? (
                    <Form.Item
                      label="Хугацаа"
                      name="duration"
                      rules={[
                        { required: true, message: "Хугацаагаа сонгоно уу" },
                      ]}
                    >
                      <Select disabled={loading} loading={loading}>
                        <Option value="1y">1 жил</Option>
                        <Option value="6m">6 сар</Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        label="Дуусах хугацаа"
                        name="endDate"
                        rules={[
                          {
                            required: true,
                            message: "Дуусах хугацаа оруулна уу",
                          },
                        ]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        label="Төлөлт хийх хугацаа"
                        name="paymentSchedules"
                        rules={[
                          {
                            required: true,
                            message: "Төлөлт хийх хугацаа оруулна уу",
                          },
                        ]}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            borderRadius: "2px",
                            padding: "2px 4px",
                            border: "1px solid #d9d9d9",
                            minHeight: "32px",
                            background: "#fff",
                          }}
                        >
                          {dates.map((date) => (
                            <Tag
                              key={date}
                              closable
                              onClose={() => {
                                if (!loading) {
                                  handleClose(date);
                                }
                              }}
                              style={{
                                margin: "2px",
                                borderRadius: "4px",
                                background: "#f5f5f5",
                                border: "none",
                                zIndex: 1000,
                              }}
                            >
                              {date}
                            </Tag>
                          ))}
                          <Input
                            disabled={loading}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputConfirm}
                            placeholder="MM/DD/YYYY"
                            contentEditable={false}
                            style={{
                              border: "none",
                              boxShadow: "none",
                              flex: 1,
                              outline: "none",
                              minWidth: "100px",
                            }}
                          />
                        </div>
                        <DatePicker
                          disabled={loading}
                          value={
                            inputValue ? moment(inputValue, "YYYY-MM-DD") : null
                          }
                          onChange={handleDateChange}
                          format="YYYY-MM-DD"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            opacity: 0,
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
              <Form.Item
                label="Идэвхтэй эсэх"
                name="active"
                valuePropName="checked"
                required
              >
                <Switch
                  defaultChecked={false}
                  checkedChildren="Идэвхтэй"
                  unCheckedChildren="Идэвхгүй"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="form-flex-container">
          <div />
          <Button
            type="primary"
            key="submit"
            style={{ marginTop: "30px" }}
            icon={<PlusOutlined />}
            onClick={() => form.validateFields().then(() => form.submit())}
          >
            Бонд засах
          </Button>
        </div>
      </div>
    </>
  );
}
